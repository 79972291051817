export const rules = {
  number: [
    { required: true, message: '请输入盘点单号', trigger: 'change' },
    { max: 32, message: '超出最大长度 (32)', trigger: 'change' },
  ],
  warehouse: [
    { required: true, message: '请选择仓库', trigger: 'change' },
  ],
  remark: [
    { max: 256, message: '超出最大长度 (256)', trigger: 'change' },
  ],
}