
export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return item.isTotal ? '合计' : (index + 1)
    },
  },
  {
    title: "客户",
    dataIndex: "client_name",
    key: "client_name",
  },
  {
    title: "库位",
    dataIndex: "location_number",
    key: "location_number",
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
    key: "material_number",
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    key: "material_name",
  },
  {
    title: "批次编号",
    dataIndex: "batch_number",
    key: "batch_number",
  },
  {
    title: "账面数量",
    key: "book_quantity",
    dataIndex: "book_quantity",
  },
  {
    title: "已分配数量",
    dataIndex: "assigned_quantity",
  },
  {
    title: "可分配数量",
    dataIndex: "assignable_quantity",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
